import React from "react";

const OnePiece = () => {
	return (
		<div>
			<h1>Title- Website Headline</h1>
		</div>
	);
};

export default OnePiece;
